import {put, takeLatest, call, select, delay} from 'redux-saga/effects';

//Actions
import * as actions from '../../actions';

//Services
import {
    ApiChangePasswordPayload,
    postChangePassword,
    postLogin,
    postPasswordRecovery
} from '../../services/auth-api';

//Types
import * as Actions from '../../actions/actions';
import * as Payloads from '../../actions/payloads';
import {AxiosResponse} from 'axios';
import {ActionType} from 'typesafe-actions';
import {getSessionData} from '../generic/saga';
import {getCustomerInfoByLogin} from '../myProfile/saga';
import {
    ConfirmMfaConfigResponse,
    GetMfaConfigResponse,
    MfaConfig,
    MfaStatus,
    SessionIdPayload
} from '../../actions/payloads';
import JSONFormData from '../../../utils/JSONFormData';
import {AccessControl} from '../../../services/endpoints';
import {api} from '../../services/axios';
import {APIErrorInterface, ReduxState} from '../../types';
import i18n from '../../../services/i18n';
import toast from 'react-hot-toast';

export const PASSWORD_EXPIRED_ERROR =
    'Server.Session.alert_You_must_change_password';

export const INVALID_VERIFICATION_CODE =
    'INVALID_VERIFICATION_CODE';

export const INVALID_VERIFICATION_CODE_MAX_ATTEMPTS_REACHED =
    'INVALID_VERIFICATION_CODE_MAX_ATTEMPTS_REACHED';

export const PASSWORD_CHANGE_MISSING_OTP_ERROR = 'Server.Session.change_password.one_time_password_missed';

export function* signIn(action: ActionType<typeof actions.logIn.request>) {
    const {login, password} = action.payload;
    try {
        localStorage.setItem('user_login', login);
        localStorage.setItem('user_login_page', 'true');

        const res: AxiosResponse<SessionIdPayload> = yield postLogin({
            login: login.trim(),
            password: password.trim(),
            enable_csrf_protection: 1
        });

        if (res.data.mfa_enabled === 1 && res.data.mfa_verified === undefined) {
            yield call(generateMfaConfig,
                actions.generateMfaConfig.request(res.data));
            return;
        }
        if (res.data.mfa_enabled === 1 && res.data.mfa_verified === 0) {
            yield put(actions.twoFaValidationRequired(res.data));
            return;
        }

        yield call(continueDefaultLogin, res.data);
    } catch (err) {
        // @ts-ignore
        if (err.response) {
            // @ts-ignore
            if (err.response.data.faultcode == PASSWORD_EXPIRED_ERROR) {
                yield put(
                    actions.logInPasswordExpired({
                        login: login,
                        password: password,
                    }),
                );
            } else {
                // @ts-ignore
                yield put(actions.logIn.failure(err));
            }
        }
    }
}

export function* continueDefaultLogin(session: SessionIdPayload) {

    localStorage.setItem('session_id', session.session_id);
    localStorage.setItem('access_token', session.access_token);
    localStorage.setItem('csrf_token', session.csrf_token);
    localStorage.removeItem('default_portal_view_configuration');
    localStorage.removeItem('user_login_page');

    yield put(
        actions.logIn.success(session),
    );
    yield call(getSessionData);
    yield call(getCustomerInfoByLogin);
}

export function* changePassword(
    action: Actions.PromiseAction<Payloads.LoginPayload &
        Payloads.PasswordPayload &
        Payloads.ChangePasswordPayload>,
) {
    const {login, password, new_password} = action.payload;

    try {
        const res: AxiosResponse<{
            success: number;
        }> = yield postChangePassword({
            login: login,
            password: password,
            new_password: new_password,
        });
        yield put(actions.passwordChange.success(res.data));
    } catch (err) {
        // @ts-ignore
        yield put(actions.passwordChange.failure(err));
    }
}

export function* changeExpiredPassword(
    action: Actions.PromiseAction<Payloads.LoginPayload &
        Payloads.PasswordPayload &
        Payloads.ChangePasswordPayload & ApiChangePasswordPayload>,
) {
    const {login, password, new_password, one_time_password} = action.payload;

    try {
        const res: AxiosResponse<{
            success: number;
        }> = yield postChangePassword({
            login: login,
            password: password,
            new_password: new_password,
            one_time_password: one_time_password
        });

        yield put(actions.changeExpiredPassword.success(res.data));

        delay(1);

        yield call(signIn, actions.logIn.request({login: login, password: new_password}));

        if(one_time_password?.length) {
            delay(1);
            
            const {waiting2FA} = yield select(
                (state: ReduxState) => state.auth,
            );

            if(waiting2FA) {
                yield call(validate2FaCode, actions.validate2FaCode.request({
                    sessionId: waiting2FA,
                    one_time_password: one_time_password
                }));
            }
        }

        if(action.payload.onSuccess)
        {
            action.payload.onSuccess();
        }

    } catch (err) {
        // @ts-ignore
        yield put(actions.changeExpiredPassword.failure(err));
    }
}

export function* passwordRecovery(
    action: ActionType<typeof actions.passwordRecovery.request>,
) {
    try {
        const res: AxiosResponse<{
            success: number;
        }> = yield postPasswordRecovery(action.payload);
        yield put(actions.passwordRecovery.success(res.data));
    } catch (err) {
        // @ts-ignore
        yield put(actions.passwordRecovery.failure(err));
    }
}

export function* emailMePasswordRecovery(
    action: Actions.PromiseAction<Payloads.MailMePayload>,
) {
    try {
        const res: AxiosResponse<{
            success: number;
        }> = yield postPasswordRecovery(action.payload);

        if (res.data.success) {
            action.payload.callback?.();
        }

        yield put(actions.sendRecoveryPasswordEmail.success(res.data));
    } catch (err) {
        // @ts-ignore
        yield put(actions.sendRecoveryPasswordEmail.failure(err));
    }
}

export function* signInFromAdmin(action: ActionType<typeof actions.signInFromAdmin.request>) {
    try {
        const {value} = action.payload;
        const data = JSON.parse(Buffer.from(value, "base64").toString());
        const session_id = data.pb_session_id;
        const token = data.access_token;

        yield put(actions.removeAuthDataAndReload({reload: false}));

        localStorage.setItem('session_id', session_id);
        localStorage.setItem('access_token', token);
        localStorage.setItem('csrf_token', data.csrf_token);
        localStorage.setItem('default_portal_view_configuration', data.i_portal_view_configuration);
        yield put(
            actions.logIn.success({
                session_id: session_id,
                access_token: token,
                csrf_token: data.csrf_token
            }),
        );

        yield call(getSessionData);
        yield call(getCustomerInfoByLogin);
    } catch (err) {
        // @ts-ignore
        yield put(actions.logIn.failure(err));
    }
}

export function* removeAuthDataAndReload(action: ActionType<typeof actions.removeAuthDataAndReload>) {

    localStorage.removeItem('session_id');
    localStorage.removeItem('user_info');
    localStorage.removeItem('user_email');
    localStorage.removeItem('csrf_token');
    localStorage.removeItem('default_portal_view_configuration');
    localStorage.removeItem('porta_one_menu');
    localStorage.removeItem('user_login');

    if (action.payload?.reload) {
        window.location.reload();
    }
}

export function* generateMfaConfig(action: ActionType<typeof actions.generateMfaConfig.request>) {
    try {
        const body = new JSONFormData(action.payload.session_id, action.payload.csrf_token);

        const response: AxiosResponse<MfaConfig> = yield api.post(AccessControl.GenerateMfaConfig, body);

        const config: MfaConfig = {
            ...response.data,
            sessionId: action.payload
        };
        yield put(actions.generateMfaConfig.success(config));
    } catch (err) {
        // @ts-ignore
        yield put(actions.generateMfaConfig.failure(err));
    }
}

export function* confirmMfaConfig(action: ActionType<typeof actions.confirmMfaConfig.request>) {
    try {
        const body = new JSONFormData(action.payload.sessionId.session_id, action.payload.sessionId.csrf_token);

        body.setParams({
            ...action.payload,
            sessionId: undefined
        });
        const response: AxiosResponse<ConfirmMfaConfigResponse> = yield api.post(AccessControl.ConfirmMfaConfig, body);

        if (response.data?.success) {
            yield put(actions.confirmMfaConfig.success(response.data));
            if (action.payload.sessionId.mfa_enabled !== -1) {
                yield call(continueDefaultLogin, action.payload.sessionId);
            }
            action.payload.onSuccess?.();
        } else {
            const obj: APIErrorInterface = {
                faultcode: INVALID_VERIFICATION_CODE,
                faultstring: i18n.t<string>('screens:twoFa.incorrectCode')
            };
            yield put(actions.confirmMfaConfig.failure(obj));
        }
    } catch (err) {
        // @ts-ignore
        yield put(actions.confirmMfaConfig.failure(err));
    }
}

export function* validate2FaCode(action: ActionType<typeof actions.validate2FaCode.request>) {

    try {
        const body = new JSONFormData(action.payload.sessionId.session_id, action.payload.sessionId.csrf_token);

        body.setParams({
            ...action.payload,
            sessionId: undefined
        });
        const response: AxiosResponse<ConfirmMfaConfigResponse> = yield api.post(AccessControl.VerifyOtp, body);

        const invalidCodeObject: APIErrorInterface = {
            faultcode: INVALID_VERIFICATION_CODE,
            faultstring: i18n.t<string>('screens:twoFa.incorrectCode')
        };

        if (response.data?.success) {
            yield put(actions.validate2FaCode.success(response.data));
            yield call(continueDefaultLogin, action.payload.sessionId);
        } else {
            const leftAttempts = (response.data?.mfa_attempts || -1);
            if (leftAttempts >= 0) {
                invalidCodeObject.faultstring = i18n.t<string>(leftAttempts === 1
                    ? 'screens:twoFa.incorrectCodeAttemptCount'
                    : 'screens:twoFa.incorrectCodeAttemptsCount', {
                    value: response.data?.mfa_attempts?.toString()
                });
            }
            //incorrectCodeAttemptCount
            if (leftAttempts === 0) {
                invalidCodeObject.faultcode = INVALID_VERIFICATION_CODE_MAX_ATTEMPTS_REACHED;
            }
            yield put(actions.validate2FaCode.failure(invalidCodeObject));
        }
    } catch (err) {
        const invalidCodeObject: APIErrorInterface = {
            faultcode: INVALID_VERIFICATION_CODE_MAX_ATTEMPTS_REACHED,
            faultstring: i18n.t<string>('screens:twoFa.incorrectCode')
        };
        // @ts-ignore
        yield put(actions.validate2FaCode.failure(invalidCodeObject));
    }
}

export function* getMfaConfig(action: ActionType<typeof actions.getMfaConfig.request>) {
    try {
        const {session_id, csrf_token} = yield select(
            (state: ReduxState) => state.auth,
        );

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        const response: AxiosResponse<GetMfaConfigResponse> = yield api.post(AccessControl.GetMfaConfig, body);
        yield put(actions.getMfaConfig.success(response.data));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        let error: APIErrorInterface = err?.response?.data || err?.data;
        if (!error?.faultcode?.length) {
            error = {
                faultcode: 'Server.AccessControl.get_mfa_config.not_exists',
                faultstring: "Can't access 'get_mfa_config' method in AccessControl"
            };
        }
        yield put(actions.getMfaConfig.failure(error));
    }
}

export function* updateMfaUsed(action: ActionType<typeof actions.updateMfaUsed.request>) {
    try {
        const {session_id, csrf_token} = yield select(
            (state: ReduxState) => state.auth,
        );

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        const response: AxiosResponse<ConfirmMfaConfigResponse> = yield api.post(AccessControl.SetMfaConfig, body);

        if ((response.data.success || 0) > 0) {
            if (action.payload.use_mfa === MfaStatus.Disabled) {
                toast(i18n.t<string>('screens:twoFa.successDisabledNotification'));
            }

            const setupConfigure = action.payload.mfa_configured !== undefined && action.payload.mfa_configured !== null;
            const obj: GetMfaConfigResponse = {
                mfa_used: action.payload.use_mfa,
                mfa_configured: setupConfigure ? (action.payload.mfa_configured || 0) : (action.payload.use_mfa === MfaStatus.Enabled ? 1 : 0),
                effective_mfa_used: action.payload.use_mfa,
                object: action.payload.object,
                i_object: action.payload.i_object,
            };
            yield put(actions.updateMfaUsed.success(obj));
            action.payload.onSuccess?.();
        } else {
            yield put(actions.updateMfaUsed.failure(response.data));
        }
    } catch (err) {
        yield put(actions.updateMfaUsed.failure({
            success: 0,
            mfa_attempts: 0
        }));
    }
}

export function* resetMfa(action: ActionType<typeof actions.resetMfa.request>) {
    try {
        const {session_id, csrf_token, individualMfaConfig} = yield select(
            (state: ReduxState) => state.auth,
        );

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        const response: AxiosResponse<ConfirmMfaConfigResponse> = yield api.post(AccessControl.ResetMfaConfig, body);

        const obj: GetMfaConfigResponse = {
            ...individualMfaConfig,
            mfa_configured: action.payload.one_time_password?.length ? 1 : 0
        };

        if ((response.data.success || 0) > 0) {
            yield put(actions.resetMfa.success(obj));
            toast(i18n.t<string>('screens:twoFa.successResetNotification'));
            action.payload.onSuccess?.();
        } else {
            yield put(actions.resetMfa.failure(response.data));
        }
    } catch (err) {
        yield put(actions.resetMfa.failure({
            success: 0,
            mfa_attempts: 0
        }));
    }
}

export const authSaga = [
    takeLatest(actions.logIn.request, signIn),
    takeLatest(
        actions.sendRecoveryPasswordEmail.request,
        emailMePasswordRecovery,
    ),
    takeLatest(actions.passwordChange.request, changePassword),
    takeLatest(actions.changeExpiredPassword.request, changeExpiredPassword),
    takeLatest(actions.passwordRecovery.request, passwordRecovery),
    takeLatest(actions.signInFromAdmin.request, signInFromAdmin),
    takeLatest(actions.removeAuthDataAndReload, removeAuthDataAndReload),
    takeLatest(actions.generateMfaConfig.request, generateMfaConfig),
    takeLatest(actions.confirmMfaConfig.request, confirmMfaConfig),
    takeLatest(actions.validate2FaCode.request, validate2FaCode),
    takeLatest(actions.getMfaConfig.request, getMfaConfig),
    takeLatest(actions.updateMfaUsed.request, updateMfaUsed),
    takeLatest(actions.resetMfa.request, resetMfa),
];
