import {
    all,
    call,
    delay,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import * as actions from '../../actions';
import {APIErrorInterface, ReduxState} from '../../types';
import JSONFormData from '../../../utils/JSONFormData';
import {ActionType} from 'typesafe-actions';
import axios, {AxiosResponse} from 'axios';
import {
    AutoAttendantListItem,
    AutoAttendantMenu,
    AutoAttendantMenuTransition,
    MenuAction,
    MenuItem,
    UserInput,
    userInputsWeights,
} from '../../types/AutoAttendant';
import {api} from '../../services/axios';
import {
    CreateExtensionFaultCode,
    CustomerExtension,
    ExtensionType,
    UpdateExtensionFaultCode,
} from '../../types/Extension';
import {
    CustomerDIDNumberType,
    CustomerNumberType,
} from '../../types/CustomerDIDNumber';
import dayjs from '../../../services/customDayJs';
import {
    fetchAccountList,
    getCountriesList,
    getGlobalCustomerInfo,
    getSubdivisionsData,
    getTimeZonesList,
} from '../generic/saga';
import {MusicOnHoldFlag, ServiceFeature, ServiceFeatureName} from '../../types/ServiceFeature';
import {CustomerInfo, CustomerInfoDetails} from '../../types/CustomerInfo';
import {EditAutoAttendantForm} from '../../../views/AutoAttendants/Details/AutoAttendantsDetails';
import {compareObjectsAndReturnDifferencesInValues} from '../../../utils/compareObjects';
import toast from 'react-hot-toast';
import qs from 'qs';
import {showErrorToast} from '../../../utils/showErrorToast';
import i18n from '../../../services/i18n';
import {TimeZone} from '../../types/TimeZone';
import {AccountListRequest, AccountListResponse} from '../../types/Account';
import {YesNo} from '../../types/CallScreening';
import {addEnableToDelete, mapToItem} from '../../../utils/transformers';
import {VoicemailSettings} from '../../types/Voicemail';
import {
    IncomingFormType,
    mappedValues,
    MappedValuesType,
} from '../../../components/Forms/AutoAttendants/Incoming/utils';
import {
    Account,
    AutoAttendant,
    Customer,
    DID,
    Voicemail,
} from '../../../services/endpoints';
import {getProducts} from '../dashboard/saga';
import Config from '../../../config.json';
import {CallQueue} from '../../types/CallQueue';
import {
    createMenuCall,
    editMenuActions,
    editMenuPrompts,
    editMenuSettings,
    editMenuTransition,
} from '../menus/saga';
import {getExtensionCallHistoryList} from '../calls/saga';
import {convertUserLocalTimeToUtc} from '../../../utils/dateWithTimezoneConversion';
import {ExtensionsListItem} from '../../reducers/extensions/extensions/reducer';
import {
    editExtensionPlan,
    getExtensionProducts,
} from '../extensions/plan/saga';
import {
    getCallBarringRules,
    updateCallBarringRules,
} from '../extensions/callBarring/saga';
import {editCallScreeningDetails} from '../extensions/callScreening/saga';
import {
    addCustomerExtension,
    deleteExtension,
    getExtensionDialingRule,
    updateExtensionStatusCall,
} from '../extensions/extensions/saga';
import {editServiceFeatures, getAllServiceFeatures} from '../extensions/serviceFeatures/saga';
import {getCallScreeningTabData} from '../extensions/extensionTabs/saga';
import {
    editCallRecording,
    editVoicemailSettings,
    getExtensionVoicemailSettings,
    getGreetingsFilesNames
} from '../extensions/voicemail/saga';
import {CallRecordingFormType} from '../../../components/Forms/Extensions/CallRecording/CallRecordingForm.utils';
import {getServiceFeatureValue} from '../../../utils/extensions/getServiceValue';
import {editExtensionCallForwardingSettings} from '../extensions/callForwarding/saga';
import {timeWindowFromTimeWindowIntervals} from '../../../utils/extensions/CallScreeningTimeWindowToPeriod';
import {daysSelectItems} from '../../../utils/extensions/RingScheduleSummary';
import {getCallQueues} from '../callQueues/saga';
import {IntervalStatus} from "../../../components/IntervalSelect/IntervalSelect.utils";
import {recordingValidationMsg} from "../../../utils/recordingValidationMsg";
import {MohInfo} from "../../types/AccountMoh";
import {getExtensionMohDetails} from "../extensions/mohs/saga";

interface VoicemailSettingsExtended extends VoicemailSettings {
    disa_password?: string;
}

const dateFormat = 'YYYY-MM-DD HH:mm:ss';
export const AUTO_ATTENDANT_NOT_FOUND_API_ERROR = 'AUTO_ATTENDANT_NOT_FOUND_API_ERROR';
export const ACCOUNT_FOLLOW_ME_ACCESS_DENIED = 'Server.Account.get_account_followme.access_denied';
export const ACCOUNT_PERMITTED_SIP_PROXIES_ACCESS_DENIED = 'Server.Account.get_permitted_sip_proxies_info.access_denied';
export const ACCOUNT_CALL_PROCESSING_RULES_ACCESS_DENIED = 'Server.Account.get_call_processing_rule_list.access_denied';
export const ACCOUNT_CALL_PROCESSING_LIST_ACCESS_DENIED = 'Server.Account.get_call_processing_condition_list.access_denied';
export const ACCOUNT_CALL_DIALING_RULE_ACCESS_DENIED = 'Server.DialingRule.get_dialing_rule_info.access_denied';

export function* getAutoAttendantDetails(
    action: ActionType<typeof actions.getAutoAttendantDetails.request>,
) {
    try {
        yield call(getAutoAttendantInfo, action);

        const autoAttendantInfo: ExtensionsListItem | undefined = yield select(
            (state: ReduxState) => state.autoAttendants.autoAttendantInfo,
        );

        if (
            autoAttendantInfo?.account_info?.id &&
            autoAttendantInfo?.account_info?.um_domain
        ) {
            yield call(
                getAutoAttendantMenus,
                actions.getAutoAttendantMenus.request({
                    login: autoAttendantInfo?.account_info?.id,
                    domain: autoAttendantInfo?.account_info?.um_domain,
                }),
            );

            yield call(
                getExtensionVoicemailSettings,
                actions.getExtensionVoicemailSettings.request({
                    accountId: autoAttendantInfo?.account_info?.id.toString(),
                    domain: autoAttendantInfo?.account_info.um_domain,
                }),
            );

            yield call(
                getGreetingsFilesNames,
                actions.getGreetingsFilesNames.request({
                    accountId: autoAttendantInfo?.account_info?.id.toString(),
                    domain: autoAttendantInfo?.account_info.um_domain,
                }),
            );
        }

        if (autoAttendantInfo?.account_info?.i_customer) {
            yield call(
                getCustomerDetailsForAutoAttendant,
                actions.getCustomerInfo.request({
                    i_customer: autoAttendantInfo?.account_info?.i_customer,
                }),
            );
        }

        yield call(getCustomerDidNumbersForAutoAttendant);
        yield call(getTimeZonesList);
        yield call(getCountriesList);
        yield call(getExtensionProducts);

        if (autoAttendantInfo?.i_account) {
            yield call(
                getCallBarringRules,
                actions.getCallBarringRules.request({
                    i_account: autoAttendantInfo?.i_account,
                }),
            );

            if (autoAttendantInfo.i_customer) {
                yield call(
                    getCallScreeningTabData,
                    actions.getCallScreeningTabData.request({
                        i_customer: autoAttendantInfo.i_customer,
                        i_account: autoAttendantInfo.i_account,
                    }),
                );
            }
        }

        yield put(actions.getAutoAttendantDetails.success());
    } catch (err: any) {
        if (err.response?.data?.faultstring) {
            showErrorToast(err.response?.data?.faultstring);
        } else {
            showErrorToast(err);
        }
        yield put(actions.getAutoAttendantDetails.failure());
    }
}

export function* getAutoAttendantInfo(
    action: ActionType<typeof actions.getAutoAttendantDetails.request>,
) {
    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);

    yield call(getProducts);

    let extension_id: string | undefined = undefined;
    let accountId: string | undefined = undefined;

    let listResponse:
        | AxiosResponse<{
        account_list: ExtensionType[];
    }>
        | undefined = undefined;

    try {
        extension_id = `${action.payload.id}`;
        body.setParams({
            has_extension: 1,
            get_only_real_accounts: 1,
            get_not_closed_accounts: 1,
            get_status: 1,
            extension_id: extension_id,
        });

        listResponse = yield api.post(Account.GetAccountList, body);

        if (listResponse?.data.account_list.length === 0) {
            throw 'Not found';
        }
    } catch (err) {
        accountId = `${action.payload.id}`;
        body.setParams({
            has_extension: 0,
            get_only_real_accounts: 1,
            get_not_closed_accounts: 1,
            get_status: 1,
            id: accountId,
        });

        listResponse = yield api.post(Account.GetAccountList, body);
    }

    const item = listResponse?.data.account_list?.[0];

    if (!item) {
        const tooltipText = i18n.t<string>('tooltips:autoAttendants.autoAttendantNotFound', {
            value: extension_id
        });
        yield put(
            actions.getAutoAttendantInfo.failure({
                faultcode: AUTO_ATTENDANT_NOT_FOUND_API_ERROR,
                faultstring: tooltipText
            } as APIErrorInterface));
        return;
    }

    let extensionItem: ExtensionsListItem | undefined;
    if (!accountId) {
        body.setParams({
            extension: `${action.payload.id}`,
            get_main_office_extensions: 1,
        });

        const extensionListResponse: AxiosResponse<{
            extensions_list: ExtensionsListItem[];
        }> = yield api.post(Customer.GetExtensionsList, body);

        extensionItem = extensionListResponse.data.extensions_list?.[0];
    }

    body.setParams({i_account: item?.i_account});
    
    let numbers: string[] = [];
    let apiError: APIErrorInterface | undefined = undefined;
    try {
        const didResponse: AxiosResponse<CustomerNumberType> = yield api.post(
            DID.GetCustomerNumbers,
            body,
        );

        numbers = didResponse.data.number_list?.map(
            (number) => number.did_number,
        );
    }
    catch (err: any) {
        apiError = err?.response?.data;
    }

    const didNumber: string | undefined = item?.did_number;
    didNumber && numbers?.push(didNumber);

    body.setParams({
        i_account: item?.i_account,
        with_customer_info: '1',
        detailed_info: '1',
        get_service_features: [
            ServiceFeatureName.Cli,
            ServiceFeatureName.UnifiedMessaging,
            ServiceFeatureName.CallProcessing,
            ServiceFeatureName.CallBarring,
            ServiceFeatureName.CallRecording,
            ServiceFeatureName.DefaultAction,
        ],
    });

    const response: AxiosResponse<{
        account_info: ExtensionType;
    }> = yield api.post(Account.GetAccountInfo, body);

    //@ts-ignore
    const mainProducts = yield select(
        (state: ReduxState) => state?.dashboard?.mainProducts,
    );

    let customerInfo: CustomerInfoDetails | undefined;

    if (item?.i_customer) {
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            i_customer: item.i_customer,
        });

        const response: AxiosResponse<CustomerInfo> = yield api.post(
            Customer.GetCustomerInfo,
            body,
        );
        customerInfo = response.data.customer_info;
    }

    yield put(
        actions.getAutoAttendantInfo.success({
            id: item?.extension_id,
            name: item?.extension_name,
            numbers: (item?.did_number && [item?.did_number]) || [],
            i_account: item?.i_account,
            i_customer: item?.i_customer,
            account_id: item?.i_account.toString(),
            account_info: response?.data.account_info,
            product_visible_name: item?.product_visible_name,
            didNumbers: numbers,
            i_c_ext: extensionItem?.i_c_ext,
            delete_enable: !!(
                item?.extension_id &&
                mainProducts?.find((p: any) => p?.i_product == item?.i_product)
            ),
            customerInfo,
            apiError: apiError
        }),
    );
}

export function* getAutoAttendantMenus(
    action: ActionType<typeof actions.getAutoAttendantMenus.request>,
) {
    if (action?.payload?.login && action.payload.domain) {
        const {autoAttendantMenus} = yield select(
            (state: ReduxState) => state.autoAttendants,
        );

        const {session_id, access_token, csrf_token} = yield select(
            (state: ReduxState) => state.auth,
        );
        const {autoAttendantList} = yield select(
            (state: ReduxState) => state.autoAttendants,
        );

        let menuList: AutoAttendantMenu[] | undefined = undefined;
        let autoattendantMenusFoundInState: AutoAttendantListItem[] | undefined = undefined;
        let menusFoundInState: MenuItem[] | undefined = undefined;

        if (!action.payload?.ignoreCache) {
            autoattendantMenusFoundInState = autoAttendantList?.items?.filter((e: AutoAttendantListItem) => e.id == action.payload.login);
            menusFoundInState = autoattendantMenusFoundInState && autoattendantMenusFoundInState.length
                ? autoattendantMenusFoundInState[0].menu?.items
                : undefined;
        }
        const callQueues: CallQueue[] | undefined = yield select(
            (state: ReduxState) => state.callQueues.callQueues,
        );

        if (autoattendantMenusFoundInState && menusFoundInState?.length) {
            menuList = menusFoundInState.map(e => {
                return {
                    ...e
                } as AutoAttendantMenu;
            });

        } else {
            const body = new JSONFormData(session_id, csrf_token);

            body.delete('auth_info');
            body.append(
                'auth_info',
                JSON.stringify({
                    login: action.payload.login,
                    domain: action.payload.domain,
                }),
            );

            const response: AxiosResponse<{
                menu_list: AutoAttendantMenu[];
            }> = yield axios.post(AutoAttendant.GetMenuList, body, {
                baseURL: Config.SIP_API_URL,
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });

            menuList = response.data.menu_list;
        }

        let translationsFoundInState = (autoAttendantMenus as AutoAttendantMenu[])?.filter(e => e.menuTransitions && e.menuTransitions?.length)
            ?.length >= 1;

        if (translationsFoundInState) {
            const d1 = (autoAttendantMenus as AutoAttendantMenu[])
                .map(z => z.i_menu)
                .join(' ');
            const d2 = menuList
                .map(z => z.i_menu)
                .join(' ');
            if (d1 !== d2) {
                translationsFoundInState = false;
            }
        }

        if (!translationsFoundInState) {
            const promises = menuList.map((menu) => {
                const body = new JSONFormData(session_id, csrf_token);

                body.delete('auth_info');
                body.append(
                    'auth_info',
                    JSON.stringify({
                        login: action.payload.login,
                        domain: action.payload.domain,
                    }),
                );

                body.setParams({
                    i_menu: menu.i_menu,
                });

                const transitionsPromise: Promise<AxiosResponse<{
                    transition_list: AutoAttendantMenuTransition[];
                }>> =
                    axios.post(AutoAttendant.GetMenuTransitionList, body, {
                        baseURL: Config.SIP_API_URL,
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    });

                transitionsPromise.then((transitionsResponse) => {
                    menu.menuTransitions = transitionsResponse.data.transition_list
                        .sort((v, w) =>
                            //@ts-ignore
                            v.key < w.key ? -1 : 1,
                        )
                        .map((v) => {
                            const nestedMenu = menuList?.find(
                                (w) => w.i_menu === v.target_i_menu,
                            );

                            return {
                                key: userInputsWeights[v.event],
                                userInput: v.event,
                                action: v.action,
                                timeout: menu.first_digit_timeout?.toString() || '0',
                                directoryIntroPromptStatus: v.play_prompt === 'Y',
                                directoryInputPromptFileName:
                                    v.prompt_set === 1 ? 'prompt.au' : '',
                                announceExtensionNumbers: v.announce_ext_numbers === 'Y',
                                playBeforeActionStatus: v.play_prompt === 'Y',
                                playBeforeActionFileName:
                                    v.prompt_set === 1 ? 'prompt.au' : '',
                                transferCallerToPhoneNumberExtension: v.destination || '',
                                maxDigits: v.max_size?.toString() || '1',
                                menu: nestedMenu
                                    ? {...nestedMenu, menuTransitions: undefined}
                                    : undefined,
                                queue: callQueues?.find(
                                    (w) => w.i_c_queue === v.target_i_queue,
                                ),
                                transitionId: v.i_menu_transition,
                            };
                        });

                    menu.deleteEnabled = true;
                });

                return transitionsPromise;
            });

            yield all(promises);

            for (let i = 0; i < menuList.length; i++) {
                const menuId = menuList[i].i_menu;

                for (const menu of menuList) {
                    if (
                        menuList[i].deleteEnabled &&
                        menu.menuTransitions
                    ) {
                        for (const menuTransaction of menu.menuTransitions) {
                            if (
                                menuTransaction.menu?.i_menu &&
                                menuTransaction.menu.i_menu == menuId
                            ) {
                                menuList[i].deleteEnabled = false;
                                break;
                            }
                        }
                    } else {
                        break;
                    }
                }
            }
        } else {
            menuList = autoAttendantMenus;
        }

        yield put(actions.getAutoAttendantMenus.success(menuList || []));
    }
}

export function* getCustomerDidNumbersForAutoAttendant() {
    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);

    const response: AxiosResponse<CustomerNumberType> = yield api.post(
        DID.GetCustomerNumbers,
        body,
    );

    yield put(actions.getCustomerDidNumbers.success(response.data.number_list));
}

export function* getCustomerDetailsForAutoAttendant(
    action: ActionType<typeof actions.getCustomerInfo.request>,
) {
    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);

    body.setParams({
        i_customer: action.payload.i_customer,
    });

    const response: AxiosResponse<CustomerInfo> = yield api.post(
        Customer.GetCustomerInfo,
        body,
    );

    yield put(actions.getCustomerInfo.success(response.data.customer_info));
}

export function* editAutoAttendant(
    action: ActionType<typeof actions.editAutoAttendant.request>,
) {
    try {

        const initialFeatureState: ServiceFeature[] | undefined = yield select((state) => 
            state.extensions?.serviceFeatures
        );
        const callBarringFeatureInitial = initialFeatureState
            ?.find(e => e.name === ServiceFeatureName.CallBarring);

        const dataToSave = compareObjectsAndReturnDifferencesInValues(
            action.payload.initialValues,
            action.payload.changedValues,
        );

        yield editExtensionDetails(dataToSave, action);
        yield editCallFlow(dataToSave, action);
        //@ts-ignore
        yield updateCallBarringRules(dataToSave, action, callBarringFeatureInitial);
        //@ts-ignore
        yield editCallScreeningDetails(dataToSave, action);
        //@ts-ignore
        yield editExtensionPlan(dataToSave, action);
        yield editCallRecording(dataToSave as CallRecordingFormType, action);
        yield editExtensionCallForwardingSettings(dataToSave, action);

        toast(i18n.t<string>('screens:autoAttendants.autoAttendantEdited'));
        yield put(actions.editAutoAttendant.success());

        yield delay(1000);
        
        const extensionId =
            action.payload.changedValues.extensionNumber ||
            action.payload.initialValues.extensionNumber;

        if (!action.payload.blockRedirection) {
            location?.replace(
                `${extensionId}?${qs.stringify({
                    tab: action.payload.redirectTab,
                })}`,
            );
        } else if((action.payload.changedValues.extensionNumber &&
            action.payload.changedValues.extensionNumber !== action.payload.initialValues.extensionNumber) || 
            (action.payload.initialValues.extensionNumber && action.payload.changedValues.extensionName !== action.payload.initialValues.extensionName)) {
                yield put(actions.getAutoAttendantsList.request());
        }
        
    } catch (err: any) {
        const errorMsg = recordingValidationMsg(err.response.data.faultcode, err.response?.data?.faultstring);

        showErrorToast(
            errorMsg,
            err.response?.data?.faultcode,
            [
                UpdateExtensionFaultCode.DuplicateId,
                UpdateExtensionFaultCode.ExtHGNumberInUse,
            ],
        );
        yield put(actions.editExtension.failure(err.response?.data));
        yield put(actions.editAutoAttendant.failure());
    }
}

export function* editExtensionDetails(
    data: Partial<EditAutoAttendantForm>,
    action: ActionType<typeof actions.editAutoAttendant.request>,
) {
    const {session_id, access_token, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    const autoAttendantInfo: ExtensionsListItem | undefined = yield select(
        (state: ReduxState) => state.autoAttendants.autoAttendantInfo,
    );
    const extension: ExtensionsListItem | undefined = yield select(
        (state: ReduxState) => state.autoAttendants.autoAttendantDetails?.extension,
    );

    const accountInfo: Partial<CustomerExtension> = {};

    if (data.extensionName || data.extensionNumber) {
        let extId: number | undefined;
        if (!action.payload.extensionId) {
            extId = extension?.i_c_ext;
        }
        if (action.payload.extensionId || extId) {
            const body = new JSONFormData(session_id, csrf_token);
            accountInfo.i_c_ext = action.payload.extensionId || extId;
            accountInfo.i_account = action.payload.accountId;
            accountInfo.name = data.extensionName;
            accountInfo.id = data.extensionNumber;
            body.setParams({...accountInfo});

            yield api.post(Customer.UpdateCustomerExtension, body);
        } else if (
            data.extensionName &&
            data.extensionNumber &&
            action.payload.accountId
        ) {
            yield addCustomerExtension(
                data.extensionName,
                data.extensionNumber,
                action.payload.accountId,
            );
        }
    }

    const account: Partial<ExtensionType> = {
        i_account: action.payload.accountId,
    };

    if (data.postalCode !== undefined) {
        account.zip = data.postalCode;
    }
    if (data.state !== undefined) {
        account.state = data.state;
    }
    if (data.city !== undefined) {
        account.city = data.city;
    }
    if (data.address !== undefined) {
        account.baddr1 = data.address;
    }
    if (data.country !== undefined) {
        account.country = data.country;
    }
    if (data.emailAddress !== undefined) {
        account.email = data.emailAddress;
    }

    if (data.timezone !== undefined) {
        const timezones: TimeZone[] = yield select(
            (state: ReduxState) => state.generic.timeZonesList,
        );

        const timezoneId = timezones.find(
            (v) => v.time_zone_name === data.timezone,
        )?.i_time_zone;

        if (timezoneId) {
            account.time_zone_name = data.timezone;
            account.i_time_zone = timezoneId;
        }
    }

    body.setParams({account_info: account});

    if (Object.keys(account).length > 1) {
        yield api.post(Account.UpdateAccount, body);
    }

    if (data.unifiedMessaging != undefined
        || data.faxMailboxSwitcher != undefined
        || data.greetingType != undefined
        || data.requirePinStatus != undefined
        || data.autoPlayStatus != undefined
        || data.announceDateStatus != undefined
        || data.pin != undefined
        || data.unifiedMessagingEmailAddress != undefined
        || data.fileFormat != undefined
        || data.greetingFileName != undefined
        || data.greetingFile != undefined
        || data.emailAddressIncoming != undefined
        || data.emailOption != undefined
        || data.faxFormat != undefined) {

        const unifiedMessagingValue = data.unifiedMessaging != undefined ? data.unifiedMessaging : action.payload.initialValues.unifiedMessaging;
        const faxMailboxSwitcherValue = data.faxMailboxSwitcher != undefined ? data.faxMailboxSwitcher : action.payload.initialValues.faxMailboxSwitcher;

        const radioButtonsChanged = data.unifiedMessaging != undefined
            || data.faxMailboxSwitcher != undefined
            || unifiedMessagingValue !== action.payload.initialValues.unifiedMessaging
            || faxMailboxSwitcherValue !== action.payload.initialValues.faxMailboxSwitcher
        ;

        if (!unifiedMessagingValue) {
            //3. unified_messaging is disabled
            if (radioButtonsChanged) {
                const params = {
                    i_account: action.payload.accountId,
                    service_features: [
                        {
                            name: ServiceFeatureName.UnifiedMessaging,
                            attributes: [
                                {
                                    name: 'fax_only_mode',
                                    effective_values: ['N'],
                                },
                            ],
                            flag_value: 'N',
                            effective_flag_value: "N",
                        },
                    ],
                };

                body.setParams(params);

                yield api.post(Account.UpdateServiceFeatures, body);
            }
        } else if (faxMailboxSwitcherValue) {
            //1. unified_messaging is enabled, fax_only is enabled (voicemail disabled)
            if (radioButtonsChanged) {
                const params = {
                    i_account: action.payload.accountId,
                    service_features: [
                        {
                            locked: '0',
                            name: ServiceFeatureName.UnifiedMessaging,
                            attributes: [
                                {
                                    name: 'fax_only_mode',
                                    values: ['Y'],
                                },
                            ],
                            flag_value: 'Y',
                        },
                    ],
                };

                body.setParams(params);

                yield api.post(Account.UpdateServiceFeatures, body);
            }
        } else {
            //2. unified_messaging is enabled, fax_only is disabled (voicemail enabled)
            if (radioButtonsChanged) {
                const params = {
                    i_account: action.payload.accountId,
                    service_features: [
                        {
                            locked: '0',
                            name: ServiceFeatureName.UnifiedMessaging,
                            attributes: [
                                {
                                    name: 'fax_only_mode',
                                    values: ['N'],
                                },
                            ],
                            flag_value: 'Y',
                        },
                    ],
                };

                body.setParams(params);

                yield api.post(Account.UpdateServiceFeatures, body);
            }

            yield editVoicemailSettings(data, {
                payload: {
                    //@ts-ignore
                    id: autoAttendantInfo?.account_info?.id,
                    domain: autoAttendantInfo?.account_info?.um_domain || '',
                    accountId: action.payload.accountId
                }
            });
        }
    }

    if (data.onHoldMusicStatus !== undefined || data.onHoldMusicName) {
        const onHoldMusicStatus =
            data.onHoldMusicStatus ??
            action.payload.initialValues.onHoldMusicStatus;

        const selectedMusicName =
            data.onHoldMusicName ||
            action.payload.initialValues.onHoldMusicName;

        const mohItems: MohInfo[] | undefined = yield select(
            (state: ReduxState) => state.extensions.mohDetails?.items,
        );

        const selectedMusicIndex =
            mohItems
                ?.find((v) => v.name === selectedMusicName)
                ?.i_moh?.toString() || '0';

        yield editServiceFeatures(
            action.payload.accountId,
            [
                {
                    name: ServiceFeatureName.MusicOnHold,
                    flag_value: onHoldMusicStatus
                        ? MusicOnHoldFlag.Enabled
                        : MusicOnHoldFlag.Disabled,
                    effective_flag_value: onHoldMusicStatus
                        ? MusicOnHoldFlag.Enabled
                        : MusicOnHoldFlag.Disabled,
                    attributes: [
                        {
                            effective_values: [selectedMusicIndex],
                            name: 'i_moh',
                            values: [selectedMusicIndex],
                        },
                    ],
                },
            ]
        );
    }

    if (data.didNumber) {
        const oldDidNumbers = action.payload.initialValues.didNumber;

        const customerDidNumbersList: CustomerDIDNumberType[] = yield select(
            (state: ReduxState) => state.autoAttendants.customerDidNumbers,
        );

        const customerDidNumbers: {
            [key in string]: CustomerDIDNumberType;
        } = {};

        customerDidNumbersList?.forEach((v) => {
            customerDidNumbers[v.did_number] = v;
        });

        for (const v of data.didNumber) {
            if (!oldDidNumbers.includes(v)) {
                body.setParams({
                    i_did_number: customerDidNumbers[v].i_did_number,
                    i_master_account: action.payload.accountId,
                });
                yield api.post(DID.AssignDIDToAccount, body);
            }
        }

        for (const v of oldDidNumbers) {
            if (!data.didNumber.includes(v)) {
                body.setParams({
                    i_did_number: customerDidNumbers[v].i_did_number,
                    i_master_account: action.payload.accountId,
                    dont_release_to_pool: 1,
                });
                yield api.post(DID.CancelDIDAssignment, body);
            }
        }
    }

    if (
        data.defaultAnsweringMode !== undefined ||
        data.defaultAnsweringTimeout !== undefined
    ) {
        const flagValue =
            data.defaultAnsweringMode ??
            action.payload.initialValues.defaultAnsweringMode;

        const timeoutValue =
            data.defaultAnsweringTimeout ??
            action.payload.initialValues.defaultAnsweringTimeout;

        body.setParams({
            i_account: action.payload.accountId,
            service_features: [
                {
                    name: ServiceFeatureName.DefaultAction,
                    effective_flag_value: flagValue,
                    flag_value: flagValue,
                    attributes: [
                        {
                            name: 'timeout',
                            effective_values: [timeoutValue],
                            values: [timeoutValue],
                        },
                    ],
                },
            ],
        });

        yield api.post(Account.UpdateServiceFeatures, body);
    }

    let shouldUpdateIncoming = false;

    const keys = Object.keys(data) as (keyof Omit<
        IncomingFormType,
        | 'defaultAnsweringMode'
        | 'timeout'
        | 'unifiedMessagingLocked'
        | 'callScreeningLocked'
        | 'faxMailboxSwitcher'
        | 'callScreeningSwitcher'
        | 'DISASwitcher'
    >)[];

    keys.forEach((dataKey) => {
        // @ts-ignore
        if (mappedValues[dataKey] !== undefined) {
            shouldUpdateIncoming = true;
        }
    });
    if (shouldUpdateIncoming) {
        body.delete('auth_info');
        body.append(
            'auth_info',
            JSON.stringify({
                login: autoAttendantInfo?.account_info?.id,
                domain: autoAttendantInfo?.account_info?.um_domain,
            }),
        );
        const valuesToSave: Partial<MappedValuesType> = {};

        keys.forEach((dataKey) => {
            // @ts-ignore
            const key = mappedValues[dataKey] as keyof MappedValuesType;
            if (key) {
                if (key === mappedValues.DISASwitcher) {
                    //@ts-ignore
                    valuesToSave[key] = data[dataKey] ? 'yes' : 'no';
                } else {
                    // @ts-ignore
                    valuesToSave[key] = data[dataKey];
                }
                body.setParams({
                    vm_settings: valuesToSave,
                });
            }
        });

        yield axios.post(Voicemail.SetVmSettings, body, {
            baseURL: Config.SIP_API_URL,
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
    }
}

export function* editCallFlow(
    data: Partial<EditAutoAttendantForm>,
    action: ActionType<typeof actions.editAutoAttendant.request>,
) {
    if (data.menus) {
        for (const changedMenu of data.menus) {
            const oldMenu = action.payload.initialValues.menus.find(
                (v: any) => v.menuId === changedMenu.menuId,
            );
            if (oldMenu) {
                const dataToSave = compareObjectsAndReturnDifferencesInValues(
                    oldMenu,
                    changedMenu,
                );

                const editMenuAction = actions.editMenu.request({
                    menuId: changedMenu.menuId,
                    initialValues: oldMenu,
                    changedValues: changedMenu,
                });

                yield editMenuSettings(dataToSave, editMenuAction);
                yield editMenuPrompts(dataToSave, editMenuAction);
                yield editMenuActions(dataToSave, editMenuAction);
            }
        }
    }
}

export function* createAutoAttendantDetailsData() {
    try {
        yield getExtensionProducts();
        yield getCustomerDidNumbersForAutoAttendant();
        yield put(actions.createAutoAttendantDetailsData.success());
    } catch (err) {
        yield put(actions.createAutoAttendantDetailsData.failure());
    }
}

export function* createNewAutoAttendant(
    action: ActionType<typeof actions.createNewAutoAttendant.request>,
) {
    try {
        const {session_id, access_token, csrf_token} = yield select(
            (state) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);

        yield addCustomerExtension(
            action.payload.extensionName || '',
            action.payload.extensionNumber,
            undefined,
            action.payload.mainProductId,
        );

        yield call(
            basicAutoAttendantDetailsData,
            actions.getAutoAttendantBasicDetailsData.request({
                id: Number(action.payload.extensionNumber),
            }),
        );

        const i_account: number = yield select(
            (state: ReduxState) =>
                state.autoAttendants.autoAttendantDetails?.extension?.i_account,
        );

        if (action.payload.didNumber) {
            const oldDidNumbers: string[] = [];

            const customerDidNumbersList: CustomerDIDNumberType[] = yield select(
                (state: ReduxState) => state.autoAttendants.customerDidNumbers,
            );

            const customerDidNumbers: {
                [key in string]: CustomerDIDNumberType;
            } = {};

            customerDidNumbersList?.forEach((v) => {
                customerDidNumbers[v.did_number] = v;
            });

            for (const v of action.payload.didNumber) {
                if (!oldDidNumbers.includes(v)) {
                    body.setParams({
                        i_did_number: customerDidNumbers[v].i_did_number,
                        i_master_account: i_account,
                    });
                    yield api.post(DID.AssignDIDToAccount, body);
                }
            }
        }

        if (action.payload.enableBusinessHours) {
            body.setParams({
                i_account,
                with_customer_info: '1',
                detailed_info: '1',
            });

            const accountInfo: AxiosResponse<{
                account_info: ExtensionType;
            }> = yield api.post(Account.GetAccountInfo, body);

            const domain = accountInfo.data.account_info.um_domain;
            const login = accountInfo.data.account_info.id;

            if (!domain || !login) {
                throw 'Error';
            }

            const bussinessMenuResponse: AxiosResponse<{
                i_menu: number;
            }> = yield createMenuCall(
                {
                    name: i18n.t('screens:autoAttendants.bussinesHours'),
                    intervals: {
                        activity: IntervalStatus.OnlyFollowingTimeInterval,
                        intervals: [
                            {
                                days: action.payload.days,
                                startTime: action.payload.startTime,
                                endTime: action.payload.endTime,
                                months: [],
                                daysOfMonth: '',
                                years: [],
                                wholeDay: false,
                            },
                        ]
                    },
                    inactiveMenuAction: MenuAction.DoNothing,
                    playBeforeAction: false,
                    recordBeforeActionName: '',
                    recordBeforeActionFile: null,
                    allowCallersToDialKnownNumber: false,
                    callersToDialKnownNumberTimeout: '5',
                    errorsCount: '1',
                    actions: [],
                    menuId: 0,
                },
                login,
                domain,
            );

            body.set(
                'auth_info',
                JSON.stringify({
                    login,
                    domain,
                }),
            );

            const menuListResponse: AxiosResponse<{
                menu_list: AutoAttendantMenu[];
            }> = yield axios.post(AutoAttendant.GetMenuList, body, {
                baseURL: Config.SIP_API_URL,
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });

            const rootMenu = menuListResponse.data.menu_list.find(
                (v) => v.name === 'ROOT',
            );
            const bussinesHoursMenu = menuListResponse.data.menu_list.find(
                (v) => v.i_menu == bussinessMenuResponse.data.i_menu,
            );

            if (!rootMenu || !bussinesHoursMenu) {
                throw 'Error';
            }

            yield editMenuTransition(
                bussinesHoursMenu.i_menu,
                {
                    userInput: UserInput.NotActive,
                    action: MenuAction.DoNothing,
                    playBeforeActionStatus: false,
                },
                login,
                domain,
            );

            const timeWindow = timeWindowFromTimeWindowIntervals({
                intervals: [
                    {
                        days: daysSelectItems,
                        startTime: '00:00',
                        endTime: '23:59',
                        months: [],
                        daysOfMonth: '',
                        years: [],
                        wholeDay: false,
                    },
                ],
                timeFilterIndex: 0,
                activity: IntervalStatus.OnlyFollowingTimeInterval,
            });

            body.setParams({
                menu_info: {
                    i_menu: rootMenu.i_menu,
                    period: timeWindow.period,
                    period_desc: timeWindow.description,
                },
            });

            yield axios.post(AutoAttendant.UpdateMenu, body, {
                baseURL: Config.SIP_API_URL,
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });

            yield editMenuTransition(
                rootMenu.i_menu,
                {
                    userInput: UserInput.NotActive,
                    action: MenuAction.Menu,
                    playBeforeActionStatus: false,
                    menu: bussinesHoursMenu,
                },
                login,
                domain,
            );

            if (action.payload.enableAfterHoursAndHolidays) {
                const afterMenuResponse: AxiosResponse<{
                    i_menu: number;
                }> = yield createMenuCall(
                    {
                        name: i18n.t(
                            'screens:autoAttendants.afterHoursAndHolidays',
                        ),
                        intervals: {
                            activity: IntervalStatus.Always,
                            intervals: []
                        },
                        inactiveMenuAction: MenuAction.DoNothing,
                        playBeforeAction: false,
                        recordBeforeActionName: '',
                        recordBeforeActionFile: null,
                        allowCallersToDialKnownNumber: false,
                        callersToDialKnownNumberTimeout: '5',
                        errorsCount: '1',
                        actions: [],
                        menuId: 0,
                    },
                    login,
                    domain,
                );

                yield editMenuTransition(
                    bussinesHoursMenu.i_menu,
                    {
                        userInput: UserInput.NotActive,
                        action: MenuAction.Menu,
                        playBeforeActionStatus: false,
                        //@ts-ignore
                        menu: {
                            i_menu: afterMenuResponse.data.i_menu,
                        },
                    },
                    login,
                    domain,
                );
            }
        }

        toast(i18n.t<string>('screens:autoAttendants.autoAttendantAdded'));
        yield put(actions.createNewAutoAttendant.success());

        yield delay(2000);

        location?.replace(`auto-attendants/${action.payload.extensionNumber}`);
    } catch (err: any) {
        showErrorToast(
            err.response?.data?.faultstring,
            err.response?.data?.faultcode,
            [
                CreateExtensionFaultCode.DuplicateId,
                CreateExtensionFaultCode.MaxOfferedQuantity,
                CreateExtensionFaultCode.ExtHGNumberInUse,
            ],
        );

        yield put(actions.createNewAutoAttendant.failure(err.response?.data));
    }
}

const filterOnlyAutoAttendants = (item: AutoAttendantListItem) => {
    return item.serviceFeatures?.filter(
        (o) =>
            o.name == ServiceFeatureName.AutoAttendant &&
            o.effective_flag_value == 'Y',
    ).length;
};

export function* fetchAutoAttendantsList() {
    const limit = 300;
    const offset = 0;

    try {
        const params: Partial<AccountListRequest> & { offset: number } = {
            limit: limit,
            offset: offset,
        };

        const res: AxiosResponse<AccountListResponse> = yield fetchAccountList(
            undefined,
            params,
        );

        const total = res.data.total;

        let accountList = [...res.data.account_list];

        if (res.data.account_list.length < total) {
            for (let i = 0; i < total / limit; i++) {
                params.offset = params.offset + limit;
                const resMore: AxiosResponse<AccountListResponse> = yield fetchAccountList(
                    undefined,
                    params,
                );
                accountList = accountList.concat(resMore.data.account_list);
            }
        }

        yield getGlobalCustomerInfo();
        yield call(getProducts);
        //@ts-ignore
        const mainProducts = yield select(
            (state: ReduxState) => state.dashboard.mainProducts,
        );

        const accountMapResult = accountList.map((item)=> mapToItem(item));
        const filteredData = addEnableToDelete(
            accountMapResult.filter(filterOnlyAutoAttendants),
            mainProducts,
        );

        const blockedItems = filteredData.filter(
            (item) => item.account_info?.blocked == YesNo.Yes,
        );

        yield put(
            actions.getAutoAttendantsList.success({
                items: filteredData,
                disabled: blockedItems.length,
                total: filteredData.length,
            }),
        );
    } catch (err: any) {
        if (err.response?.data) {
            yield put(actions.getAutoAttendantsList.failure());
        }
    }
}

export function* getAutoAttendantMenuFromList(
    action: ActionType<typeof actions.getAutoAttendantMenuList.request>,
) {
    const {access_token, session_id, csrf_token} = yield select((state) => state.auth);
    const {MediaServerDomain} = yield select(
        (state) => state.generic.configData,
    );
    const body = new JSONFormData(session_id, csrf_token);

    try {
        body.setParams({
            i_account: action.payload.i_account,
            detailed_info: 1,
        });

        let accountInfo: AxiosResponse<any> | undefined = undefined;
        if (!MediaServerDomain) {
            accountInfo = yield api.post(Account.GetAccountInfo, body);
        }

        const um_domain =
            MediaServerDomain ?? accountInfo?.data.account_info.um_domain;

        body.delete('params');
        body.delete('auth_info');
        body.append(
            'auth_info',
            JSON.stringify({
                login: action.payload.id_pin,
                domain: um_domain,
            }),
        );

        if (um_domain) {
            const res: AxiosResponse<any> = yield axios.post(
                AutoAttendant.GetMenuList,
                body,
                {
                    baseURL: Config.SIP_API_URL,
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                },
            );

            yield put(
                actions.getAutoAttendantMenuList.success({
                    id_pin: action.payload.id_pin,
                    items: res.data.menu_list,
                    um_domain: um_domain
                }),
            );
        } else {
            yield put(
                actions.getAutoAttendantMenuList.success({
                    id_pin: action.payload.id_pin,
                    items: [],
                    um_domain: um_domain
                }),
            );
        }
    } catch (e) {
        yield put(
            actions.getAutoAttendantMenuList.failure({
                id_pin: action.payload.id_pin,
            }),
        );
    }
}

export function* getDISAState() {
    const {session_id, access_token, csrf_token} = yield select(
        (state: ReduxState) => state.auth,
    );
    const autoAttendantInfo: ExtensionsListItem | undefined = yield select(
        (state: ReduxState) => state.autoAttendants.autoAttendantInfo,
    );
    try {
        if (autoAttendantInfo?.account_info?.id && autoAttendantInfo?.account_info?.um_domain) {
            const body = new JSONFormData(session_id, csrf_token);
            body.delete('auth_info');
            body.append(
                'auth_info',
                JSON.stringify({
                    login: autoAttendantInfo?.account_info?.id,
                    domain: autoAttendantInfo?.account_info?.um_domain,
                }),
            );

            const res: AxiosResponse<{
                vm_settings: VoicemailSettingsExtended;
            }> = yield axios.post(Voicemail.GetVmSettings, body, {
                baseURL: Config.SIP_API_URL,
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });

            yield put(
                actions.getExtensionVoicemailSettings.success(res.data.vm_settings),
            );

            const value: 'Y' | 'N' =
                res.data?.vm_settings.enable_disa === 'yes' ? 'Y' : 'N';

            yield put(
                actions.getDISAState.success({
                    enableDisa: value,
                    DISAPassword: res.data?.vm_settings?.disa_password || '',
                    faxFormat: res.data?.vm_settings?.fax_file || '',
                    emailAddressIncoming: res.data?.vm_settings?.ext_email || '',
                    emailOption: res.data?.vm_settings?.ext_email_action || '',
                }),
            );
        }
    } catch (e) {}
}

export function* deleteAutoAttendant(
    action: ActionType<typeof actions.deleteCustomerAutoAttendant.request>,
) {
    try {
        yield deleteExtension(
            action.payload.id,
            action.payload.i_customer,
            action.payload.i_c_ext,
        );
        yield put(
            actions.deleteCustomerAutoAttendant.success({
                id: action.payload.id,
            }),
        );
        toast(i18n.t<string>('screens:autoAttendants.autoAttendantDeleted'));
        action.payload.callback?.();
    } catch (e: any) {
        showErrorToast(e.response?.data?.faultstring);
        yield put(actions.deleteCustomerAutoAttendant.failure());
    }
}

export function* setAutoAttendantStatus(
    action: ActionType<typeof actions.setAutoAttendantStatus.request>,
) {
    try {
        yield updateExtensionStatusCall(action.payload);
        yield put(actions.setAutoAttendantStatus.success(action.payload));
        if (action.payload.account_info.blocked === YesNo.Yes) {
            toast(
                i18n.t<string>('screens:autoAttendants.autoAttendantDisabled'),
            );
        } else {
            toast(
                i18n.t<string>('screens:autoAttendants.autoAttendantEnabled'),
            );
        }
    } catch (err: any) {
        showErrorToast(err.response?.data?.faultstring);
    }
}

export function* basicAutoAttendantDetailsData(
    action: ActionType<typeof actions.getAutoAttendantBasicDetailsData.request>,
) {
    const {id} = action.payload;

    yield call(
        getAutoAttendantInfo,
        actions.getAutoAttendantDetails.request({id}),
    );

    const {autoAttendantInfo} = yield select(
        (state: ReduxState) => state.autoAttendants,
    );

    const {callQueues} = yield select(
        (state: ReduxState) => state.callQueues,
    );

    if (autoAttendantInfo) {
        yield getAllServiceFeatures(
            autoAttendantInfo?.account_info?.i_account,
        );

        if (!callQueues) {
            yield call(getCallQueues);
        }
    }

    yield put(
        actions.getAutoAttendantBasicDetailsData.success(autoAttendantInfo),
    );
}

export function* fetchMenuTabData(
    action: ActionType<typeof actions.getCallFlowTabData.request>,
) {
    try {
        const {callQueues} = yield select(
            (state: ReduxState) => state.callQueues,
        );

        if (!callQueues) {
            yield call(getCallQueues);
        }

        yield all([
            call(
                getAutoAttendantMenus,
                actions.getAutoAttendantMenus.request({
                    login: action.payload.id,
                    domain: action.payload.um_domain,
                }),
            ),
        ]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
        if (e.response?.data?.faultstring) {
            showErrorToast(e.response?.data?.faultstring);
        } else {
            showErrorToast(e);
        }
        yield put(actions.getCallFlowTabData.failure());
    }

    yield put(actions.getCallFlowTabData.success());
}

export function* getAutoAttendantExtensionTabData() {
    yield call(getTimeZonesList);
    yield call(getCountriesList);
    yield call(getDISAState);
    
    let apiError: APIErrorInterface | undefined = undefined;
    try
    {
        yield call(getCustomerDidNumbersForAutoAttendant);
    }
    catch (err: any)
    {
        apiError = err?.response?.data;
    }

    const autoAttendantInfo: ExtensionsListItem | undefined = yield select(
        (state: ReduxState) => state.autoAttendants.autoAttendantInfo,
    );

    if (autoAttendantInfo?.account_info?.country) {
        yield call(
            getSubdivisionsData,
            actions.getSubdivisionData.request({
                iso_3166_1_a2: autoAttendantInfo?.account_info?.country,
            }),
        );
    }

    yield put(actions.getAutoAttendantExtensionTabData.success());
}

export function* getAutoAttendantCallBaringTabData(
    action: ActionType<
        typeof actions.getAutoAttendantCallBaringDetailsData.request
    >,
) {
    yield call(
        getCallBarringRules,
        actions.getCallBarringRules.request({
            i_account: action.payload.i_account,
        }),
    );

    yield put(actions.getAutoAttendantCallBaringDetailsData.success());
}

export function* getAutoAttendantCallRecordingTabData(
    action: ActionType<
        typeof actions.getAutoAttendantCallRecordingTabData.request
    >,
) {
    const timezoneOffset: number = yield select(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    yield call(
        getExtensionCallHistoryList,
        actions.getExtensionCallHistory.request({
            i_account: action.payload.i_account,
            limit: 5,
            offset: 0,
            from: convertUserLocalTimeToUtc(
                dayjs().subtract(1, 'month').format(dateFormat),
                timezoneOffset,
            ),
            till: convertUserLocalTimeToUtc(
                dayjs().format(dateFormat),
                timezoneOffset,
            ),
            withInitCalls: true,
            isCallRecording: true,
        }),
    );

    yield put(actions.getAutoAttendantCallRecordingTabData.success());
}

export function* getAutoAttendantPlanTabData() {
    yield call(getExtensionProducts);
    yield put(actions.getAutoAttendantPlanTabData.success());
}

export function* getAutoAttendantCallSettingsTabData(
    action: ActionType<
        typeof actions.getAutoAttendantCallSettingsTabData.request
    >,
) {
    const serviceFeatures: ServiceFeature[] = yield select(
        (state: ReduxState) => state.extensions.serviceFeatures || [],
    );

    if (
        getServiceFeatureValue(
            ServiceFeatureName.CallBarring,
            serviceFeatures,
        ) != 'N'
    ) {
        yield call(
            getCallBarringRules,
            actions.getCallBarringRules.request({
                i_account: action.payload.i_account,
            }),
        );
    }

    yield call(getDISAState);

    yield call(
        getExtensionMohDetails,
        actions.getExtensionMohDetails.request({
            accountId: action.payload.i_account,
        }),
    );

    const i_dialing_rule = getServiceFeatureValue(
        ServiceFeatureName.VoiceDialing,
        serviceFeatures,
        'i_dial_rule',
    );

    if (i_dialing_rule) {
        yield getExtensionDialingRule(i_dialing_rule);
    }

    yield put(actions.getAutoAttendantCallSettingsTabData.success());
}

export const autoAttendantsSaga = [
    takeLatest(
        actions.getAutoAttendantCallSettingsTabData.request,
        getAutoAttendantCallSettingsTabData,
    ),
    takeLatest(
        actions.getAutoAttendantPlanTabData.request,
        getAutoAttendantPlanTabData,
    ),
    takeLatest(
        actions.getAutoAttendantCallRecordingTabData.request,
        getAutoAttendantCallRecordingTabData,
    ),
    takeLatest(
        actions.getAutoAttendantCallBaringDetailsData.request,
        getAutoAttendantCallBaringTabData,
    ),
    takeLatest(
        actions.getAutoAttendantExtensionTabData.request,
        getAutoAttendantExtensionTabData,
    ),
    takeLatest(actions.getCallFlowTabData.request, fetchMenuTabData),
    takeLatest(
        actions.getAutoAttendantDetails.request,
        getAutoAttendantDetails,
    ),
    takeLatest(actions.editAutoAttendant.request, editAutoAttendant),
    takeLatest(actions.createNewAutoAttendant.request, createNewAutoAttendant),
    takeLatest(
        actions.createAutoAttendantDetailsData.request,
        createAutoAttendantDetailsData,
    ),
    takeLatest(actions.getAutoAttendantsList.request, fetchAutoAttendantsList),
    takeEvery(actions.getDISAState.request, getDISAState),
    takeLatest(
        actions.deleteCustomerAutoAttendant.request,
        deleteAutoAttendant,
    ),
    takeLatest(actions.setAutoAttendantStatus.request, setAutoAttendantStatus),
    takeEvery(
        actions.getAutoAttendantMenuList.request,
        getAutoAttendantMenuFromList,
    ),

    takeLatest(
        actions.getAutoAttendantBasicDetailsData.request,
        basicAutoAttendantDetailsData,
    ),
];
