import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';
import DateTimeCell from '../../../components/Calls/DateTimeCell';
import { Colors } from '../../../styles/Colors';
import TwoLineCell from '../../../components/Calls/TwoLineCell';
import { CalleeOrCaller, SipCall, SipCallState, SipCallType } from '../../../store/actions/ringgroups/payloads';
import { ReactComponent as IncomingCallIcon } from '../../../assets/incomingCallList.svg';
import { ReactComponent as OutgoingCallIcon } from '../../../assets/outgoingCallList.svg';
import CustomizedTooltip from '../../../components/Tooltip/Tooltip';
import {ExtensionType} from '../../../store/types/Extension';
import {RingGroupType} from '../../../store/types/RingGroup';
import {ExtensionsListItem} from "../../../store/reducers/extensions/extensions/reducer";

export type ActiveCallsFiltersProps = {
    state?: SipCallState;
    type?: SipCallType;
    ringGroup?: string;
    extension?: ExtensionsListItem,

};

export type DropDownDictionaryItem<T> = {
    name: string;
    value: T | undefined;
};

export type GroupedDropDownDictionaryItem<T> = {
    name: string;
    value: T | undefined;
    category: string;
};

export const useStyles = makeStyles(() => ({
    extensionField:{
      width:200
    },
    tableContainer: {
        maxWidth: 1040,

        '& tr td.MuiTableCell-root.MuiTableCell-body:nth-child(4) span': {
            marginLeft: -7,
        },
        
        '& th:nth-child(2)': {
            padding: 0,
            width: '24px !important'
        },
        
        '& tr td:nth-child(2)': {
            padding: '0 !important',
            width: '24px !important',
            justifyContent: 'end'
        },
    },
    connectingText: {
        fontSize: 14,
        fontStyle: 'italic',
        color: Colors.Gray5,
        fontFamily: 'Roboto',
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 32,
        width: "auto"
    },
    itemsContainer: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'flex-start',
    },
    inputsContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        width:'auto',

        '& > *': {
            marginRight: 16,
        },
    },
    button: {
        '& .MuiButton-label': {
            color: Colors.White,
        },
        width: 96,
        marginTop: 8
    },
    durationHeader: {
        paddingLeft: 15,
        width: 'fit-content'
    },
    duration: {
        fontSize: 17
    }
}));

export const initialValues: ActiveCallsFiltersProps = {
    state: undefined,
    type: undefined,
    ringGroup: undefined,
    extension:undefined
};

const displayCallerText = (accounts: ExtensionType[], obj?: CalleeOrCaller) => {
    if(!obj?.extension_id) {
        return '';
    }

    const filtered = accounts.filter(e => e.extension_id?.length
        && obj.extension_id == e.extension_id 
        && obj.centrex_id + '' == e.i_customer + '');

    if(!filtered.length) {
        return '';
    }

    return obj.extension_id + (filtered[0].extension_name ? (" - " + filtered[0].extension_name) : '');
}

export const generateColumns = (
    accounts: ExtensionType[],
    huntGroupList: RingGroupType[] | undefined,
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    timezoneOffset: number,
    userDateTimeFormat?: string,
): Column<SipCall>[] => {
    return [
        {
            accessor: 'connect_time',
            Header: t<string>('screens:calls.startTime'),
            width: 1,
            Cell: function Cell(params) {
                const time = params.row.original.start_time || params.row.original.connect_time;
                return (
                    <DateTimeCell
                        timezoneOffset={timezoneOffset}
                        //@ts-ignore
                        time={time}
                        customDateFormat={userDateTimeFormat}
                        hideDateWhenIsToday
                    />
                );
            },
        },
        {
            accessor: 'type',
            Header: '',
            Cell: function Cell(params) {
                const icon = params.row.original.type === SipCallType.Incoming 
                    ? (<IncomingCallIcon />)
                    : (<OutgoingCallIcon />);
                const callTypeTooltip = t(
                    `enums:sipCallType.${params.row.original.type}`,
                );
                return (
                    <CustomizedTooltip title={callTypeTooltip} copy={false}>
                        {icon}
                    </CustomizedTooltip>
                );
            },
            minWidth: 24,
            maxWidth: 24
        },
        {
            accessor: 'caller',
            Header: t<string>('screens:calls.caller'),
            width: 1,
            Cell: function Cell(params) {
                return (
                    <TwoLineCell
                        headerText={params.row.original.caller?.id}
                        descriptionText={
                            displayCallerText(accounts, params.row.original.caller)
                        }
                    />
                );
            },
        },
        {
            accessor: 'callee',
            Header: t<string>('screens:calls.destination'),
            width: 1.3,
            Cell: function Cell(params) {
                return (
                    <TwoLineCell
                        headerText={params.row.original.callee?.id}
                        descriptionText={
                            displayCallerText(accounts, params.row.original.callee)
                        }
                    />
                );
            },
        },
        {
            accessor: 'state',
            Header: t<string>('screens:calls.callState'),
            width: 2,
            Cell: function Cell(params) {
                const call = params.row.original;
                const huntId = call.callee?.huntgroup_id || call.caller?.huntgroup_id || '';

                let secondRowText = '';
                if(call.state === SipCallState.Queued) {
                    const noc = huntGroupList?.find(e => e?.assigned_callqueue?.i_c_queue === call.queue_info?.i_c_queue);
                    secondRowText = t<string>('screens:calls.queuedAndBelow', {
                        position: call.queue_info?.position || 0,
                        noc: noc?.name || ''
                    });
                }
                else if(call.state === SipCallState.Dequeued) {
                    const noc = huntGroupList?.find(e => e?.assigned_callqueue?.i_c_queue === call.queue_info?.i_c_queue);
                    secondRowText = t<string>('screens:calls.toRingGroup', {
                        noc: noc?.name || '',
                        huntId: noc?.id
                    });
                }
                else if(huntId) {
                    const noc = huntGroupList?.find(e => e?.id === huntId);
                    secondRowText = t<string>('screens:calls.viaRingGroup', {
                        noc: noc?.name || '',
                        huntId: huntId
                    });
                }

                return (
                    <TwoLineCell
                        headerText={t(
                            `enums:sipCallState.${params.row.original.state}`,
                        ) + (params.row.original.state === SipCallState.Ringing || params.row.original.state === SipCallState.Trying || params.row.original.state === SipCallState.Early
                            ? '...' : '')
                        }
                        headerClassName={
                            params.row.original.state === SipCallState.Ringing || params.row.original.state === SipCallState.Trying || params.row.original.state === SipCallState.Early
                            ? classes.connectingText
                            : undefined
                        }
                        descriptionText={secondRowText}
                    />
                );
            },
        },
        {
            accessor: 'update_time',
            Header: (<div className={classes.durationHeader}>{t<string>('screens:calls.duration')}</div>),
            width: 2,
            Cell: function Cell(params) {
                return (
                    <span className={classes.duration}>
                        {params.row.original.duration}
                    </span>
                );
            }
        }
    ];
};
