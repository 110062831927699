import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../../styles/Colors';
import * as Yup from 'yup';
import i18n from 'i18next';
import {ErrorTabInteface} from "./generalFormUtils";
import { ExtensionType } from '../../../store/types/Extension';

const required = i18n.t('errors:common.emptyInput');
const min1 = i18n.t('errors:ringGroups.min1');
const max999 = i18n.t('errors:ringGroups.max999');
const max55555secs = i18n.t('errors:ringGroups.max55555secs');
const number = i18n.t('errors:ringGroups.e164Number');
const max99 = i18n.t('errors:ringGroups.max99');
const max300min = i18n.t('errors:ringGroups.max300min');

export type MohFile = {
    onHoldMusicFileName?: string;
    onHoldMusicFile?: File | null;
};

export type CallQueuePromptActionType = {
    prompt: number;
    promptAction: 'set' | 'unset' | null;
    promptName?: string;
    redirectToEntity?: number;
    waitConfirmation: boolean;
    action: number;
    promptFile: File | null;
    playPromptFile: boolean;
};

export type CallQueueFormType = {
    callQueueStatus: boolean;
    announceNumberOfCallersInQueue: boolean;
    maximumNumberOfQueuedCallers: number;
    announceEstimatedWaitTime: boolean;
    averageHandleTime?: number;
    intervalBetweenAnnouncements?: string;
    playOnHoldMusicStatus: boolean;
    onHoldMusicFileName?: string;
    onHoldMusicFile?: File | null;
    dtmfToConfirmQueueExit: string;
    timeOutForCallerInput: number;
    dispatchCallsToBusyExtensions: boolean;
    maximumWaitingTime?: number | null;
    maximumRingingTime?: number | null;
    onIncomingCallLimit: CallQueuePromptActionType;
    onMaxRingingTime: CallQueuePromptActionType;
    onMaxWaitingTime: CallQueuePromptActionType;
    queueId: number;
};

export type SelectExtensionListItem = {
    label: string;
    value: string;
    id: string;
    object: ExtensionType;
}

export const callQueuePromptActionSchema = Yup.object().shape({
    waitConfirmation: Yup.boolean().required(),
    action: Yup.number().required(),
    prompt: Yup.number().notRequired(),
    redirectToEntity: Yup.number()
        .when('action', {
            is: (value: number) =>
                value === 2 || value === 3 || value === 4,
            then: Yup.number().required(required),
            otherwise: Yup.number().notRequired(),
        })
});

export const callQueueValidationSchema = Yup.object().shape({
    callQueueStatus: Yup.boolean().required(),
    announceNumberOfCallersInQueue: Yup.boolean().required(),
    maximumNumberOfQueuedCallers: Yup.number().max(999, max999).min(1, min1).required(),
    announceEstimatedWaitTime: Yup.boolean().required(),
    averageHandleTime: Yup.number().max(300, max300min).min(1, min1).required(),
    intervalBetweenAnnouncements: Yup.number().max(999, max999).min(1, min1).required(),
    playOnHoldMusicStatus: Yup.boolean().required(),
    onHoldMusicFileName: Yup.string().when('playOnHoldMusicStatus', {
        is: (value: boolean) => value,
        then: Yup.string().required(
            i18n.t<string>('errors:ringGroups.musicIsMandatory'),
        ),
        otherwise: Yup.string().notRequired(),
    }),
    onHoldMusicFile: Yup.object().nullable().notRequired(),
    dtmfToConfirmQueueExit: Yup.string()
        .matches(/^[0-9*#]+$/, i18n.t<string>(number)).required(),
    maximumWaitingTime: Yup.number().max(999, max999).min(1, min1).notRequired(),
    maximumRingingTime: Yup.number().max(55555, max55555secs).min(1, min1).notRequired(),
    timeOutForCallerInput: Yup.number().max(99, max99).min(1, min1).required(),
    dispatchCallsToBusyExtensions: Yup.boolean().required(),
    onIncomingCallLimit: callQueuePromptActionSchema,
    onMaxRingingTime: callQueuePromptActionSchema,
    onMaxWaitingTime: callQueuePromptActionSchema,
});

export type CallQueueFormProps = ErrorTabInteface

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        maxWidth: 1044,
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 16px 0px 16px',
    },
    columnItemsContainer: {
        flexDirection: 'column',
        '& .MuiFormControl-root:first-of-type': {
            marginRight: '15px !important',
        },
        padding: '22px 16px 0px 16px',
    },
    inputs: {
        display: 'flex',

        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',

        '& .MuiFormControl-root': {
            flex: 1,
            height: 'auto',
            marginLeft: 24,
        },

        '& .MuiFormControl-root:first-of-type': {
            marginLeft: 0,
        },

        '& .MuiBox-root': {
            padding: '0px 0px 24px 0px',
        },

        '& h3': {
            marginBlockEnd: 0,
            marginTop: 30,
        },

        '& input[type="number"]::-webkit-inner-spin-button': {
            opacity: 1,
            marginRight: 10,
        },

        '& input[type="number"]::-webkit-outside-spin-button': {
            opacity: 1,
            marginRight: 10,
        },
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        maxWidth: 986,
        '& div': {
            height: 37,
            marginBottom: 0,
        },
        marginBottom: 15,
    },
    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.Gray6,
    },
    boldHeader: {
        fontWeight: 700,
    },
    musicRowBox: {
        padding: 0 + '!important',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginTop: 10,

        '& .MuiButton-label': {
            color: Colors.White,
        },

        '& .MuiFormControl-root': {
            flex: 1,
            marginRight: 14,
        },
    },
    checkbox: {
        flexDirection: 'row-reverse',
        marginLeft: 0,
        marginTop: 20,
        marginBottom: 15,
    },
    optionWithMargin: {
        marginLeft: 20,
    },

    numberInput: {
        flex: 1,
        width: 288,
        marginBottom: 24,
    },
    numberLabel: {
        marginLeft: -60,
        marginTop: 18,
    },
    onHoldMusicTitle: {
        marginRight: 35,
    },
    numberInputTooltip: {
        marginRight: 10,
        marginTop: 12,
    },
    minText: {
        position: 'absolute',
        marginTop: 16,
        marginLeft: 80,
        top: 10.5
    },

    noMarginBottom: {
        marginBottom: 0,
    },
    noPaddingTop: {
        paddingTop: 0,
        '& div': {
            paddingTop: 0,
        },
    },

    marginTop: {
        marginTop: 9,
    },

    extraPadding: {
        paddingTop: 22,
    },
    switchMargin: {
        height: 55,
        marginBottom: 8,
    },
    tinyMargin: {
        paddingTop: '0!important',
        paddingBottom: '20px!important',
    },
    sectionHeader: {
        fontWeight: 700,
        fontSize: 16,
        color: Colors.Text
    },
    actionPromptContainer: {
        maxWidth: 594,
        padding: '24px 0px 0px 40px',
        height: 'fit-content',
        background: Colors.Gray2,
        margin: '9px 0px 12px 16px',
        borderRadius: 4,
        display: "flex",
        alignItems:"flex-start",
        flexDirection: "column",
    },
    actionPromptHeader: {
        fontWeight: 400,
        fontSize: 16,
        color: Colors.Text,
        height: 'fit-content',
        marginBottom: 24
    },
    promptSelect: {
        marginTop: 24,
        width: 522,
        height: 'unset'
    },
    playPrompt: {
        width: 522,
        height: 'unset'
    },
    waitConfirmationCheckBox: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

export const callQueueValidationReqFiledsName: string[] = [
    'intervalBetweenAnnouncements',
    'dtmfToConfirmQueueExit',
    'timeOutForCallerInput',
    'maximumNumberOfQueuedCallers',
    'averageHandleTime'
];

export const addDialogClosedListener = (input: HTMLInputElement | null, callback: () => void) => {
    if(!input) return;
    const onFocus = function() {
        window.removeEventListener('focus', onFocus);
        callback();
    };
    const onClick = function() {
        window.addEventListener('focus', onFocus);
    };
    input.addEventListener('click', onClick);
    return function() {
        input.removeEventListener('click', onClick);
        window.removeEventListener('focus', onFocus);
    };
}