export enum PermissionType {
    Visible = 'visible',
    ReadOnly = 'read-only',
    Hidden = 'hidden',
}

export interface PortalPermission {
    components: { [key in string]: PortalPermission };
    name: string;
    permission: PermissionType;
}

export interface PortalComponentInfo {
    component_list: PortalComponentInfo[];
    name: string;
    permission: PermissionType;
}

export interface PortalViewInfo {
    i_portal_view_configuration: number;
    portal_url: string;
    total_components: number;
    i_portal: number;
    name: string;
    i_role: number;
    component_info: {};
}

export const CustomLinkKey = 'Custom links'


export const Permission = {
    Dashboard: {
        value: ['Dashboard'],
        AddNewExtension: {value: ['Dashboard', 'Add new extension']},
        AddNewRingGroup: {value: ['Dashboard', 'Add new ring group']},
        AddNewFaxMailbox: {value: ['Dashboard', 'Add new fax mailbox']},
        AddNewAutoAttendant: {value: ['Dashboard', 'Add new auto-attendant']},
        AddNewPortalUser: {value: ['Dashboard', 'Add new portal user']},
        AddNewSipTrunk: {value: ['Dashboard', 'Add new SIP trunk']},
    },

    CloudPBX: {
        value: ['Cloud PBX'],
        Extensions: {
            value: ['Cloud PBX', 'Extensions'],
            SwitchNode: {value: ['Cloud PBX', 'Extensions', 'Switch mode']},
            AddNewExtension: {
                value: ['Cloud PBX', 'Extensions', 'Add new extension'],
            },
            ExtensionDetails: {
                value: ['Cloud PBX', 'Extensions', 'Extension details'],
                Extension: {
                    value: [
                        'Cloud PBX',
                        'Extensions',
                        'Extension details',
                        'Extension',
                    ],
                },
                CallSettings: {
                    value: [
                        'Cloud PBX',
                        'Extensions',
                        'Extension details',
                        'Call settings',
                    ],
                },
                CallForwarding: {
                    value: [
                        'Cloud PBX',
                        'Extensions',
                        'Extension details',
                        'Call forwarding',
                    ],
                },
                CallScreening: {
                    value: [
                        'Cloud PBX',
                        'Extensions',
                        'Extension details',
                        'Call screening',
                    ],
                },
                Plan: {
                    value: [
                        'Cloud PBX',
                        'Extensions',
                        'Extension details',
                        'Plan',
                    ],
                },
                Device: {
                    value: [
                        'Cloud PBX',
                        'Extensions',
                        'Extension details',
                        'Device',
                    ],
                    AssignDevice: {
                        value: [
                            'Cloud PBX',
                            'Extensions',
                            'Extension details',
                            'Device',
                            'Assign device',
                        ],
                    },
                    ReleaseDevice: {
                        value: [
                            'Cloud PBX',
                            'Extensions',
                            'Extension details',
                            'Device',
                            'Release device',
                        ],
                    },
                    ChangeDevice: {
                        value: [
                            'Cloud PBX',
                            'Extensions',
                            'Extension details',
                            'Device',
                            'Change device',
                        ],
                    },
                },
                CallHistory: {
                    value: [
                        'Cloud PBX',
                        'Extensions',
                        'Extension details',
                        'Call history',
                    ],
                    CallDetailRecord: {
                        value: [
                            'Cloud PBX',
                            'Extensions',
                            'Extension details',
                            'Call history',
                            'Call detail record',
                        ],
                        DeleteCallRecording: {
                            value: [
                                'Cloud PBX',
                                'Extensions',
                                'Extension details',
                                'Call history',
                                'Call detail record',
                                'Delete call recording',
                            ],
                        },
                        DownloadCallRecording: {
                            value: [
                                'Cloud PBX',
                                'Extensions',
                                'Extension details',
                                'Call history',
                                'Call detail record',
                                'Download call recording',
                            ],
                        },
                    },
                },
                DeleteExtension: {
                    value: [
                        'Cloud PBX',
                        'Extensions',
                        'Extension details',
                        'Delete extension',
                    ],
                },
            },
            DeleteExtension: {
                value: ['Cloud PBX', 'Extensions', 'Delete extension'],
            },
            ViewCallHistory: {
                value: ['Cloud PBX', 'Extensions', 'View call history'],
                DownloadCallRecording: {
                    value: [
                        'Cloud PBX',
                        'Extensions',
                        'View call history',
                        'Download call recording',
                    ],
                },
            },
        },
        RingGroups: {
            value: ['Cloud PBX', 'Ring groups'],
            AddNewRingGroup: {
                value: ['Cloud PBX', 'Ring groups', 'Add new ring group'],
            },
            RingGroupDetails: {
                value: ['Cloud PBX', 'Ring groups', 'Ring group details'],
                General: {
                    value: [
                        'Cloud PBX',
                        'Ring groups',
                        'Ring group details',
                        'General',
                    ],
                },
                Members: {
                    value: [
                        'Cloud PBX',
                        'Ring groups',
                        'Ring group details',
                        'Members',
                    ],
                    AddNewMember: {
                        value: [
                            'Cloud PBX',
                            'Ring groups',
                            'Ring group details',
                            'Members',
                            'Add new member',
                        ],
                    },
                    MembersSettings: {
                        value: [
                            'Cloud PBX',
                            'Ring groups',
                            'Ring group details',
                            'Members',
                            "Member's settings",
                        ],
                    },
                },
                CallQueue: {
                    value: [
                        'Cloud PBX',
                        'Ring groups',
                        'Ring group details',
                        'Call queue',
                    ],
                },
                DeleteRingGroup: {
                    value: [
                        'Cloud PBX',
                        'Ring groups',
                        'Ring group details',
                        'Delete ring group',
                    ],
                },
            },
            DeleteRingGroup: {
                value: ['Cloud PBX', 'Ring groups', 'Delete ring group'],
            },
            ViewCallHistory: {
                value: ['Cloud PBX', 'Ring groups', 'View call history'],
                DownloadCallRecording: {
                    value: [
                        'Cloud PBX',
                        'Ring groups',
                        'View call history',
                        'Download call recording',
                    ],
                },
            },
        },
        AutoAttendants: {
            value: ['Cloud PBX', 'Auto-attendants'],
            AddNewAutoAttendant: {
                value: [
                    'Cloud PBX',
                    'Auto-attendants',
                    'Add new auto-attendant',
                ],
            },
            AutoAttendantDetails: {
                value: [
                    'Cloud PBX',
                    'Auto-attendants',
                    'Auto-attendant details',
                ],
                CallFlow: {
                    value: [
                        'Cloud PBX',
                        'Auto-attendants',
                        'Auto-attendant details',
                        'Call flow',
                    ],
                },
                Menu: {
                    value: [
                        'Cloud PBX',
                        'Auto-attendants',
                        'Auto-attendant details',
                        'Menu',
                    ],
                    AddNewMenu: {
                        value: [
                            'Cloud PBX',
                            'Auto-attendants',
                            'Auto-attendant details',
                            'Menu',
                            'Add new menu',
                        ],
                    },
                    DeleteMenu: {
                        value: [
                            'Cloud PBX',
                            'Auto-attendants',
                            'Auto-attendant details',
                            'Menu',
                            'Delete menu',
                        ],
                    },
                    MenuDetails: {
                        value: [
                            'Cloud PBX',
                            'Auto-attendants',
                            'Auto-attendant details',
                            'Menu',
                            'Menu details',
                        ],
                        MenuSettings: {
                            value: [
                                'Cloud PBX',
                                'Auto-attendants',
                                'Auto-attendant details',
                                'Menu',
                                'Menu details',
                                'Menu settings',
                            ],
                        },
                        MenuPrompts: {
                            value: [
                                'Cloud PBX',
                                'Auto-attendants',
                                'Auto-attendant details',
                                'Menu',
                                'Menu details',
                                'Menu prompts',
                            ],
                        },
                        MenuActions: {
                            value: [
                                'Cloud PBX',
                                'Auto-attendants',
                                'Auto-attendant details',
                                'Menu',
                                'Menu details',
                                'Menu actions',
                            ],
                        },
                    },
                },
                Extension: {
                    value: [
                        'Cloud PBX',
                        'Auto-attendants',
                        'Auto-attendant details',
                        'Extension',
                    ],
                },
                CallSettings: {
                    value: [
                        'Cloud PBX',
                        'Auto-attendants',
                        'Auto-attendant details',
                        'Call settings',
                    ],
                },
                CallScreening: {
                    value: [
                        'Cloud PBX',
                        'Auto-attendants',
                        'Auto-attendant details',
                        'Call screening',
                    ],
                },
                CallForwarding: {
                    value: [
                        'Cloud PBX',
                        'Auto-attendants',
                        'Auto-attendant details',
                        'Call forwarding',
                    ],
                },
                Plan: {
                    value: [
                        'Cloud PBX',
                        'Auto-attendants',
                        'Auto-attendant details',
                        'Plan',
                    ],
                },
                CallHistory: {
                    value: [
                        'Cloud PBX',
                        'Auto-attendants',
                        'Auto-attendant details',
                        'Call history',
                    ],
                    CallDetailRecord: {
                        value: [
                            'Cloud PBX',
                            'Auto-attendants',
                            'Auto-attendant details',
                            'Call history',
                            'Call detail record',
                        ],
                        DeleteCallRecording: {
                            value: [
                                'Cloud PBX',
                                'Auto-attendants',
                                'Auto-attendant details',
                                'Call history',
                                'Call detail record',
                                'Delete call recording',
                            ],
                        },
                        DownloadCallRecording: {
                            value: [
                                'Cloud PBX',
                                'Auto-attendants',
                                'Auto-attendant details',
                                'Call history',
                                'Call detail record',
                                'Download call recording',
                            ],
                        },
                    },
                },
                DeleteAutoAttendant: {
                    value: [
                        'Cloud PBX',
                        'Auto-attendants',
                        'Auto-attendant details',
                        'Delete auto-attendant',
                    ],
                },
            },
            DeleteAutoAttendant: {
                value: [
                    'Cloud PBX',
                    'Auto-attendants',
                    'Delete auto-attendant',
                ],
            },
            ViewCallHistory: {
                value: ['Cloud PBX', 'Auto-attendants', 'View call history'],
                DownloadCallRecording: {
                    value: [
                        'Cloud PBX',
                        'Auto-attendants',
                        'View call history',
                        'Download call recording',
                    ],
                },
            },
        },
        FaxMailboxes: {
            value: ['Cloud PBX', 'Fax mailboxes'],
            AddNewFaxMailbox: {
                value: ['Cloud PBX', 'Fax mailboxes', 'Add new fax mailbox'],
            },
            FaxMailboxDetails: {
                value: ['Cloud PBX', 'Fax mailboxes', 'Fax mailbox details'],
                Extension: {
                    value: [
                        'Cloud PBX',
                        'Fax mailboxes',
                        'Fax mailbox details',
                        'Extension',
                    ],
                },
                Plan: {
                    value: [
                        'Cloud PBX',
                        'Fax mailboxes',
                        'Fax mailbox details',
                        'Plan',
                    ],
                },
            },
            DeleteFaxMailbox: {
                value: ['Cloud PBX', 'Fax mailboxes', 'Delete fax mailbox'],
            },
            ViewFaxTransmissionHistory: {
                value: [
                    'Cloud PBX',
                    'Fax mailboxes',
                    'View fax transmission history',
                ],
            },
        },
        PhoneBook: {
            value: ['Cloud PBX', 'Phone book'],
            AddNewPhoneBookEntry: {
                value: ['Cloud PBX', 'Phone book', 'Add new phone book entry'],
            },
            PhoneBookEntryDetails: {
                value: ['Cloud PBX', 'Phone book', 'Phone book entry details'],
            },
            DeletePhoneBookEntry: {
                value: ['Cloud PBX', 'Phone book', 'Delete phone book entry'],
            },
        },
        Wallboard: {
            value: ['Cloud PBX', 'Wallboard'],
            AddWidget: {
                value: ['Cloud PBX', 'Wallboard', 'Add widget'],
            },
        },
    },

    Calls: {
        value: ['Calls'],
        Settings: {
            value: ['Calls', 'Settings'],
            ServiceCodes: {
                value: ['Calls', 'Settings', 'Service codes'],
            },
            CallBarring: {
                value: ['Calls', 'Settings', 'Call barring'],
            },
            MusicAndRinging: {
                value: ['Calls', 'Settings', 'Music and ringing'],
            },
            CallScreening: {
                value: ['Calls', 'Settings', 'Call screening'],
            },
            General: {
                value: ['Calls', 'Settings', 'General'],
            },
        },
        Recordings: {
            value: ['Calls', 'Recordings'],
            DeleteCallRecording: {
                value: ['Calls', 'Recordings', 'Delete call recording'],
            },
            DownloadCallRecording: {
                value: ['Calls', 'Recordings', 'Download call recording'],
            },
            CallDetailRecord: {
                value: ['Calls', 'Recordings', 'Call detail record'],
                DeleteCallRecording: {
                    value: [
                        'Calls',
                        'Recordings',
                        'Call detail record',
                        'Delete call recording',
                    ],
                },
                DownloadCallRecording: {
                    value: [
                        'Calls',
                        'Recordings',
                        'Call detail record',
                        'Download call recording',
                    ],
                }
            },
            PlayCallRecording: {
                value: [
                    'Calls',
                    'Recordings',
                    'Play call recording',
                ],
            }
        },
        Activity: {
            value: ['Calls', 'Activity'],
            DownloadCallRecording: {
                value: ['Calls', 'Activity', 'Download call recording'],
            },
            RecentCalls: {
                value: ['Calls', 'Activity', 'Recent calls'],
                CallDetailRecord: {
                    value: [
                        'Calls',
                        'Activity',
                        'Recent calls',
                        'Call detail record'
                    ],
                    DeleteCallRecording: {
                        value: [
                            'Calls',
                            'Activity',
                            'Recent calls',
                            'Call detail record',
                            'Delete call recording'
                        ]
                    },
                    DownloadCallRecording: {
                        value: [
                            'Calls',
                            'Activity',
                            'Recent calls',
                            'Call detail record',
                            'Download call recording'
                        ]
                    }
                },
                DownloadCallRecording: {
                    value: [
                        'Calls',
                        'Activity',
                        'Recent calls',
                        'Download call recording'
                    ],
                },
                PlayCallRecording: {
                    value: [
                        'Calls',
                        'Activity',
                        'Recent calls',
                        'Play call recording'
                    ]
                }
            },
            CurrentCalls: {
                value: ['Calls', 'Activity', 'Current calls'],
            }
        },
    },

    SipTrunks: {
        value: ['SIP trunks'],
        AddNewSipTrunk: {
            value: ['SIP trunks', 'Add new SIP trunk']
        },
        SipTrunksDetails: {
            value: ['SIP trunks', 'SIP trunk details'],
            SipTrunk: {
                value: ['SIP trunks', 'SIP trunk details', 'SIP trunk'],
            },
            CallSettings: {
                value: ['SIP trunks', 'SIP trunk details', 'Call settings'],
            },
            CallScreening: {
                value: ['SIP trunks', 'SIP trunk details', 'Call screening'],
            },
            CallForwarding: {
                value: ['SIP trunks', 'SIP trunk details', 'Call forwarding'],
            },
            Plan: {
                value: ['SIP trunks', 'SIP trunk details', 'Plan'],
            },
            CallHistory: {
                value: ['SIP trunks', 'SIP trunk details', 'Call history'],
                CallDetailRecord: {
                    value: ['SIP trunks', 'SIP trunk details', 'Call history', 'Call detail record'],
                    DeleteCallRecording: {
                        value: ['SIP trunks', 'SIP trunk details', 'Call history', 'Call detail record','Delete call recording']
                    },
                    DownloadCallRecording: {
                        value: ['SIP trunks', 'SIP trunk details', 'Call history', 'Call detail record','Download call recording']
                    }
                }
            },
        },
        ViewCallHistory: {
            value: ['SIP trunks', 'View call history'],
            DownloadCallRecording: {
                value: [
                    'SIP trunks',
                    'View call history',
                    'Download call recording',
                ],
            },
        },
    },

    Inventory: {
        value: ['Inventory'],
        Devices: {
            value: ['Inventory', 'Devices'],
            AddNewDevice: {
                value: ['Inventory', 'Devices', 'Add new device'],
            },
            DownloadDevices: {
                value: ['Inventory', 'Devices', 'Download devices'],
            },
            DeleteDevice: {
                value: ['Inventory', 'Devices', 'Delete device'],
            },
            DeviceDetails: {
                value: ['Inventory', 'Devices', 'Device details'],
                EditPort: {
                    value: ['Inventory', 'Devices', 'Device details', 'Edit port'],
                },
                ReleasePort: {
                    value: ['Inventory', 'Devices', 'Device details', 'Release port'],
                },
                DeleteDevice: {
                    value: ['Inventory', 'Devices', 'Device details', 'Delete device'],
                }
            }
        },
        DIDNumbers: {
            value: ['Inventory', 'DID numbers'],
            AddNewDID: {
                value: ['Inventory', 'DID numbers', 'Add new DID'],
            },
            DIDDetails: {
                value: ['Inventory', 'DID numbers', 'DID details'],
            },
            DeleteDID: {
                value: ['Inventory', 'DID numbers', 'Delete DID'],
            },
            ViewCallHistory: {
                value: ['Inventory', 'DID numbers', 'View call history'],
                DownloadCallRecording: {
                    value: [
                        'Inventory',
                        'DID numbers',
                        'View call history',
                        'Download call recording',
                    ],
                },
            },
        },
    },

    MyCompany: {
        value: ['My company'],
        MyProfile: {
            value: ['My company', 'My profile'],
            OwnUserSetting: {
                value: ['My company', 'My profile', "Own user's setting"],
            },
            Security: {
                value: ['My company', 'My profile', 'Security'],
            },
        },
        PortalUsers: {
            value: ['My company', 'Portal users'],
            AddNewPortalUser: {
                value: ['My company', 'Portal users', 'Add new portal user'],
            },
            PortalUsersDetails: {
                value: ['My company', 'Portal users', "Portal user's details"],
                PortalUsersSettings: {
                    value: [
                        'My company',
                        'Portal users',
                        "Portal user's details",
                        "Portal user's settings",
                    ],
                },
                Security: {
                    value: [
                        'My company',
                        'Portal users',
                        "Portal user's details",
                        'Security',
                    ],
                },
                DeletePortalUser: {
                    value: [
                        'My company',
                        'Portal users',
                        "Portal user's details",
                        'Delete portal user',
                    ],
                },
            },
            DeletePortalUser: {
                value: ['My company', 'Portal users', 'Delete portal user'],
            },
        },
        CompanyInfo: {
            value: ['My company', 'Company info'],
            Main: {
                value: ['My company', 'Company info', "Main"],
            },
            Branch: {
                value: ['My company', 'Company info', "Branch"],
            },
        },
        Billing: {
            value: ['My company', 'Billing'],
            Transactions: {
                value: ['My company', 'Billing', 'Transactions'],
            },
            Invoices: {
                value: ['My company', 'Billing', 'Invoices'],
            },
            PaymentMethod: {
                value: ['My company', 'Billing', 'Payment method'],
            },
            MakeAPayment: {
                value: ['My company', 'Billing', 'Make payment'],
            },
        },
    },
    CustomLink:{
        value: [CustomLinkKey],
    }
};